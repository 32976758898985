.center { 
    text-align: center;
    padding-left: 20px; 
    padding-right: 20px;
    padding-top: calc(3%);
    height: 208px;
    overflow: overlay;
}

img.image-256 
{ 
    height: 256px; 
    width: 256px;
    float: left;
}

.card-256 { 
    height: 304px; 
    margin-bottom: 40px;    
}

.card-fix {
    max-height: 304px;
    min-height: 304px;
    padding: 24px;
}

@media screen and (max-width: 600px) {

    img.image-256 
    { 
        height: 256px; width: 256px;       
    }

    .card-fix {
        max-height: 500px;
        min-height: 500px;
        height: 500px;
        padding: 24px;
    }

    .card-item-fix {
        height: 265px;
        margin-left: calc((100% - 265px) / 2);
    }

    .card-256 { 
        height: 500px; 
        margin-bottom: 40px;    
    }
}